<template>
    <base-table models-name="utenti"
                model-ref="l'utente"
                new-msg="un nuovo utente"
                :is-loading="isLoading"
                :headers="headers"
                :can-create="$store.state.authModule.utente.CAN_CREATE_UTENTE"
                @changeOptions="onChangeOptions($event)"
    >
        <template #header="{ onChangeFilter }">
            <utenti-table-header @changeOptions="onChangeOptions($event)"
                                 @changeFilter="onChangeFilter"
            ></utenti-table-header>
        </template>

        <template #NOME="{ item }">
            <i>{{ item.NOME + ' ' + item.COGNOME }}</i>
        </template>

        <template #CREATOR_TIMESTAMP="{ item }">
            <i>{{ getData(item.CREATOR_TIMESTAMP) }}</i>
        </template>

        <template #DATA_LOGIN="{ item }">
            <i>{{ !!item.DATA_LOGIN ? getData(item.DATA_LOGIN) : 'MAI' }}</i>
        </template>

    </base-table>
</template>

<script>
    import BaseTable from '@/components/aaaProject/table/BaseTable';
    import UtentiTableHeader from '@/components/secure/utenti/components/UtentiTableHeader';
    import moment from '@/moment';

    export default {
        name: 'UtentiTable',
        components: {UtentiTableHeader, BaseTable},
        props: {isLoading: Boolean},
        data: () => ({
            headers: [
                {
                    text: '#',
                    value: 'ID_REC',
                    align: 'center'
                },
                {
                    text: 'Stato ',
                    value: 'ERRORE',
                    align: 'center'
                },
                {
                    text: 'Profilo ',
                    value: 'PROFILO',
                    align: 'center'
                },
                {
                    text: 'Grado ',
                    value: 'GRADO',
                    align: 'center'
                },
                {
                    text: 'Nominativo ',
                    value: 'NOME',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Email ',
                    value: 'EMAIL',
                    align: 'center'
                },
                {
                    text: 'Iscrizione ',
                    value: 'CREATOR_TIMESTAMP',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Ultimo Accesso ',
                    value: 'DATA_LOGIN',
                    align: 'center',
                    custom: true
                },
                {
                    value: 'actions',
                    align: 'center',
                    sortable: false
                }
            ]
        }),
        methods: {
            onChangeOptions(options) {
                this.$emit('changeOptions', options);
            },
            getData(data) {
                return moment.toDate(data, 'L');
            }
        }
    }
</script>

<style scoped>

</style>
