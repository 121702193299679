var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"models-name":"utenti","model-ref":"l'utente","new-msg":"un nuovo utente","is-loading":_vm.isLoading,"headers":_vm.headers,"can-create":_vm.$store.state.authModule.utente.CAN_CREATE_UTENTE},on:{"changeOptions":function($event){return _vm.onChangeOptions($event)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var onChangeFilter = ref.onChangeFilter;
return [_c('utenti-table-header',{on:{"changeOptions":function($event){return _vm.onChangeOptions($event)},"changeFilter":onChangeFilter}})]}},{key:"NOME",fn:function(ref){
var item = ref.item;
return [_c('i',[_vm._v(_vm._s(item.NOME + ' ' + item.COGNOME))])]}},{key:"CREATOR_TIMESTAMP",fn:function(ref){
var item = ref.item;
return [_c('i',[_vm._v(_vm._s(_vm.getData(item.CREATOR_TIMESTAMP)))])]}},{key:"DATA_LOGIN",fn:function(ref){
var item = ref.item;
return [_c('i',[_vm._v(_vm._s(!!item.DATA_LOGIN ? _vm.getData(item.DATA_LOGIN) : 'MAI'))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }