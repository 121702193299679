<template>
    <h-row>

        <h-col cols="12" sm="6">
            <base-table-header-select label="Profilo"
                                      key-options="profile"
                                      :items="[
                                          {label: '--Nessun Filtro--', value: null},
                                          {label: 'OPERATORE', value: 'OPERATORE'},
                                          {label: 'CLIENTE', value: 'CLIENTE'},
                                          {label: 'COMMERCIALISTA', value: 'COMMERCIALISTA'}
                                      ]"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-select>
        </h-col>

        <h-col cols="12" sm="6">
            <base-table-header-select label="Stato"
                                      key-options="errore"
                                      :items="[
                                          {label: '--Nessun Filtro--', value: null},
                                          {label: 'ATTIVO', value: 'NONE'},
                                          {label: 'INATTIVO', value: 'NOT_NONE'},
                                          {label: 'DISATTIVATO MANUALMENTE', value: 'SELF'},
                                          {label: 'CLIENTE INATTIVO', value: 'CLIENTE_OFF'}
                                      ]"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-select>
        </h-col>

        <h-col cols="12">
            <base-table-header-filter exact
                                      @changeFilter="$emit('changeFilter', $event)"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-filter>
        </h-col>

    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseTableHeaderSelect from '@/components/aaaProject/table/BaseTableHeaderSelect';
    import BaseTableHeaderFilter from '@/components/aaaProject/table/BaseTableHeaderFilter';

    export default {
        name: 'UtentiTableHeader',
        components: {HRow, HCol, BaseTableHeaderFilter, BaseTableHeaderSelect},
        data: () => ({
            ruolo: undefined,
            cliente: undefined
        })
    }
</script>

<style scoped>

</style>
