var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h-row',[_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-table-header-select',{attrs:{"label":"Profilo","key-options":"profile","items":[
                                      {label: '--Nessun Filtro--', value: null},
                                      {label: 'OPERATORE', value: 'OPERATORE'},
                                      {label: 'CLIENTE', value: 'CLIENTE'},
                                      {label: 'COMMERCIALISTA', value: 'COMMERCIALISTA'}
                                  ]},on:{"changeOptions":function($event){return _vm.$emit('changeOptions', $event)}}})],1),_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-table-header-select',{attrs:{"label":"Stato","key-options":"errore","items":[
                                      {label: '--Nessun Filtro--', value: null},
                                      {label: 'ATTIVO', value: 'NONE'},
                                      {label: 'INATTIVO', value: 'NOT_NONE'},
                                      {label: 'DISATTIVATO MANUALMENTE', value: 'SELF'},
                                      {label: 'CLIENTE INATTIVO', value: 'CLIENTE_OFF'}
                                  ]},on:{"changeOptions":function($event){return _vm.$emit('changeOptions', $event)}}})],1),_c('h-col',{attrs:{"cols":"12"}},[_c('base-table-header-filter',{attrs:{"exact":""},on:{"changeFilter":function($event){return _vm.$emit('changeFilter', $event)},"changeOptions":function($event){return _vm.$emit('changeOptions', $event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }